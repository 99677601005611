import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Introduce from './introduce';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Page, { PageProps } from './page/[page]';
import { CategoryProps } from '@src/components/common/category/Category';
import { cvtJson2Library } from '@src/lib/public-lib';
import LibraryService from '@src/services/library/LibraryService';

export type MainProps = {
	buyList: any[];
	cartList: any[];
}

export default function Home(props: PageProps)
{
	const meta = {
		title: 'YULBGM',
		siteDescription: 'YULCOMPANY Inc',
		pageUrl: 'https://www.yulbgm.com',
	};

	const router = useRouter();

	useEffect(() => {
		router.replace({
			pathname: '/page/1',
		});
	});

	return (
		<Page { ...props } />
	)
}

export async function getServerSideProps(context: any)
{
    // const { params, query } = context;
    // const page: number = params && params.page ? parseInt(params.page) : 1;
    // const listNum: number = query && query.listNum ? parseInt(query.listNum) : 20;
    // const filter = query.tags ? query.tags : query.search;
    // let mainData: any[] = [];

    // // 메인 데이터
    // if (query.search)
    // {
    //     const searchRes = await LibraryService.searchList({ keywords: filter, limit: listNum, page: page });
    //     mainData = page > 0 ? searchRes.map((data: any) => 
    //         cvtJson2Library(data),
    //     ) : [];
    // }
    // else
    // {
    //     const mainRes = await LibraryService.mainList({ limit: listNum, page: page, filter: filter, locale: context.locale });
    //     mainData = page > 0 ? mainRes.map((data: any) => 
    //         cvtJson2Library(data),
    //     ) : [];
    // }

    // // 카테고리 이름 정보
    // const codeName: string = context.locale === "ko" ? "MST_CD_NM" : "MST_CD_NM_EN";
    // const categoryRes = await LibraryService.categoryList({ target: ["MST_CD", codeName], locale: context.locale });
    // const categoryData: CategoryProps[] = categoryRes.map((data: any) => {
    //     return {
    //         id: data.MST_CD,
    //         name: context.locale === "ko" ? data.MST_CD_NM : data.MST_CD_NM_EN,
    //         num: 0,
    //         tags: ""
    //     }
    // });

    // // 태그 데이터
    // const tagMap: Map<string, string> = new Map();
    // const libraryRes = await LibraryService.libraryList({ locale: context.locale, target: ["LIBRARY_GENRE_CD", "MOOD_CD", "THEME_CD", "SCENE_CD", "SOUND_EFFECT_CD", "BGM_CD", "LIBRARY_INST_CD", "NATION"], limit: 0, page: 0 });
    // libraryRes.map((data: any) => {
    //     if (data.LIBRARY_GENRE_CD)
    //         tagMap.set("LIBRARY_GENRE_CD", (tagMap.get("LIBRARY_GENRE_CD") ? tagMap.get("LIBRARY_GENRE_CD") + (data.LIBRARY_GENRE_CD + ", ") : data.LIBRARY_GENRE_CD + ", "));
    //     if (data.MOOD_CD)
    //         tagMap.set("MOOD_CD", (tagMap.get("MOOD_CD") ? tagMap.get("MOOD_CD") + (data.MOOD_CD + ", ") : data.MOOD_CD + ", "));
    //     if (data.THEME_CD)
    //         tagMap.set("THEME_CD", (tagMap.get("THEME_CD") ? tagMap.get("THEME_CD") + (data.THEME_CD + ", ") : data.THEME_CD + ", "));
    //     if (data.SCENE_CD)
    //         tagMap.set("SCENE_CD", (tagMap.get("SCENE_CD") ? tagMap.get("SCENE_CD") + (data.SCENE_CD + ", ") : data.SCENE_CD + ", "));
    //     if (data.LIBRARY_GENRE_CD)
    //         tagMap.set("LIBRARY_GENRE_CD", (tagMap.get("LIBRARY_GENRE_CD") ? tagMap.get("LIBRARY_GENRE_CD") + (data.LIBRARY_GENRE_CD + ", ") : data.LIBRARY_GENRE_CD + ", "));
    //     if (data.SOUND_EFFECT_CD)
    //         tagMap.set("SOUND_EFFECT_CD", (tagMap.get("SOUND_EFFECT_CD") ? tagMap.get("SOUND_EFFECT_CD") + (data.SOUND_EFFECT_CD + ", ") : data.SOUND_EFFECT_CD + ", "));
    //     if (data.BGM_CD)
    //         tagMap.set("BGM_CD", (tagMap.get("BGM_CD") ? tagMap.get("BGM_CD") + (data.BGM_CD + ", ") : data.BGM_CD + ", "));
    //     if (data.LIBRARY_INST_CD)
    //         tagMap.set("LIBRARY_INST_CD", (tagMap.get("LIBRARY_INST_CD") ? tagMap.get("LIBRARY_INST_CD") + (data.LIBRARY_INST_CD + ", ") : data.LIBRARY_INST_CD + ", "));
    //     if (data.NATION)
    //         tagMap.set("NATION", (tagMap.get("NATION") ? tagMap.get("NATION") + (data.NATION + ", ") : data.NATION + ", "));
    // });
    
    // const tagData: string = JSON.stringify(Object.fromEntries(tagMap));
    // const dataRes = await LibraryService.dataNum({ tags: query.tags, search: query.search });
    
    return {
        props: {
            page: 1,
            mainData: [],
            categoryData: [],
            tagData: "",
            dataNum: 0,
            ...(await serverSideTranslations(context.locale ?? "en", ['common', 'header', "page"])),
        }
    }
}